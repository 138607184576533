import appConfig from '@/app.config';
import { getGlobalData } from 'lib/global';
import Link from 'next/link';

export default function FourOhFour() {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: "100vh", width: "100vw" }}>
      <img src="/logo-badge.svg" />
      <h1>Oops! This page can’t be found.</h1>
      <Link className='cta mt-5' href="/">
        Back to home
      </Link>
    </div>
  );
}


export async function getStaticProps() {
  return await getGlobalData({}, undefined, { revalidate: appConfig.revalidate });
}